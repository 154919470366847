import React from "react";
import {
  CmsContent,
  Container,
  Layout,
  NewBikeSingleButtons,
  NewBikeSingleGallery,
  NewBikeSingleHeader,
  NewBikeSinglePrices,
  NewBikeSingleSpecs,
  NewBikeSingleVideo,
} from "components";
import { ContactFormBlock, ContentAndImageGrid, FeaturedPosts } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { ContainerSpacingEnum, ContainerWidthEnum } from "utils";
import { NewBikeByIdQuery } from "codegen";
import "./new-single.scss";
import { PageProps, graphql } from "gatsby";

interface CPTPageContext {
  id: string;
  title: string;
  cptType: string;
}

const NewBikeSingle = ({ data: { newBike, wp } }: PageProps<NewBikeByIdQuery, CPTPageContext>): JSX.Element => {
  const { title, content, seo, postTypeNewBike } = newBike;
  const {
    webAppSettings: {
      webAppSettings: { globalContactBlock },
    },
  } = wp;

  return (
    <Layout pageTitle={title} seo={seo} className="bike-single">
      <>
        <NewBikeSingleHeader data={newBike} />

        <NewBikeSingleGallery data={newBike} />

        <NewBikeSinglePrices data={newBike} />

        <Container
          className="new-single-content"
          containerWidth={ContainerWidthEnum.LAYOUT_SMALL}
          sectionSpacing={ContainerSpacingEnum.SECTION_NONE}
        >
          <CmsContent content={content} />
        </Container>

        <Container containerWidth={ContainerWidthEnum.LAYOUT_SMALL} sectionSpacing={ContainerSpacingEnum.SECTION_NONE}>
          <NewBikeSingleButtons data={newBike} />
        </Container>

        <Container
          className="new-single-sosumi"
          containerWidth={ContainerWidthEnum.LAYOUT_SMALL}
          sectionSpacing={ContainerSpacingEnum.SECTION_NONE}
        >
          <p dangerouslySetInnerHTML={{ __html: postTypeNewBike?.sosumi }} />
        </Container>

        <NewBikeSingleSpecs data={newBike} />

        <NewBikeSingleVideo data={newBike} />

        {postTypeNewBike?.callToActionBlock && <ContentAndImageGrid data={postTypeNewBike?.callToActionBlock} />}

        <FeaturedPosts data={postTypeNewBike?.relatedBikes} />

        {globalContactBlock && <ContactFormBlock data={globalContactBlock} />}
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "new-motorcycles" })(NewBikeSingle);

export const CPTQueryById = graphql`
  query NewBikeById($id: String!, $previousPostId: String, $nextPostId: String) {
    # selecting the current page by id
    newBike: wpNewMotorcycle(id: { eq: $id }) {
      ...NewMotorcyclesFields
      postTypeNewBike {
        gallery {
          colourName
          colourSwatch {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, width: 60)
              }
            }
          }
          imagery {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: BLURRED, width: 1000)
                }
              }
            }
          }
        }
        rideAwayPrice
        perWeekPrice
        financeTotalCost
        interestRate
        comparisonRate
        sosumi
        specifications {
          title
          content
        }
        promotionalVideo
        relatedBikes {
          fieldGroupName
          feedTitle
          feedType
          __typename
          # To save some headaches for someone later. This query will fail during build if a selected/related post is DRAFTED or TRASHED.
          # Check the failing page for either of those
          feedItems {
            ...NewMotorcyclesFields
            ...UsedMotorcyclesFields
          }
        }
        callToActionBlock {
          fieldGroupName
          content
          imageConfiguration
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, width: 500)
                }
              }
            }
          }
        }
      }
    }
    previous: wpNewMotorcycle(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpNewMotorcycle(id: { eq: $nextPostId }) {
      uri
      title
    }
    wp {
      webAppSettings {
        webAppSettings {
          globalContactBlock {
            row {
              contentType
              formId
              content
              images {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 80, placeholder: BLURRED, width: 700)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
